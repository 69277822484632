<template>
    <footer
        v-if="footer"
        id="foot"
        class="app-footer"
        :class="$config.public.variant === 'bchollos' ? 'bg-site-secondary text-white' : 'bg-white'"
    >
        <section class="app-footer-section">
            <div class="logo">
                <NuxtLink class="logo__link" no-prefetch to="/" aria-label="Go home" rel="follow">
                    <img
                        class="logo__image"
                        :src="$assets.brand.footerLogo"
                        :alt="$lang.brand"
                        :title="$lang.brand"
                    />
                </NuxtLink>

                <FooterLocation
                    :countries="dropdownCountries"
                    v-if="$config.public.variant !== 'cupohosting' && $config.public.variant !== 'bchollos'"
                />
            </div>

            <article class="columns">
                <div class="column dropdown">
                    <h3 class="column__head">
                        <span class="column__title">{{ footer.firsts_title }}</span>
                        <picture class="column__icon">
                            <img
                                class="column__icon-image"
                                :src="$assets.gray.arrowDown"
                                width="16px"
                                height="16px"
                                alt="flecha abajo"
                                title="flecha abajo"
                            />
                        </picture>
                    </h3>
                    <ul class="column-list">
                        <li class="column-list__item" v-for="(link, index) in footer.firsts" :key="index">
                            <a
                                v-if="link.external"
                                :href="link.url"
                                class="column-list__link"
                                :class="
                                    $config.public.variant === 'bchollos'
                                        ? 'text-white'
                                        : 'text-gray-800 hover:text-site-primary'
                                "
                                rel="nofollow noopener noreferrer"
                                target="_blank"
                            >
                                {{ link.title }}
                            </a>
                            <NuxtLink
                                no-prefetch
                                v-else
                                :to="link.url"
                                class="column-list__link"
                                :class="
                                    $config.public.variant === 'bchollos'
                                        ? 'text-white'
                                        : 'text-gray-800 hover:text-site-primary'
                                "
                                >{{ link.title }}</NuxtLink
                            >
                        </li>
                    </ul>
                </div>
                <div class="column dropdown">
                    <h3 class="column__head">
                        <span class="column__title">{{ footer.seconds_title }}</span>
                        <picture class="column__icon">
                            <img
                                class="column__icon-image"
                                :src="$assets.gray.arrowDown"
                                width="16px"
                                height="16px"
                                alt="flecha abajo"
                                title="flecha abajo"
                            />
                        </picture>
                    </h3>
                    <ul class="column-list">
                        <li class="column-list__item" v-for="(link, index) in footer.seconds" :key="index">
                            <a
                                v-if="link.external"
                                :href="link.url"
                                class="column-list__link"
                                :class="
                                    $config.public.variant === 'bchollos'
                                        ? 'text-white'
                                        : 'text-gray-800 hover:text-site-primary'
                                "
                                rel="nofollow noopener noreferrer"
                                target="_blank"
                            >
                                {{ link.title }}
                            </a>
                            <NuxtLink
                                no-prefetch
                                v-else
                                :to="link.url"
                                class="column-list__link"
                                :class="
                                    $config.public.variant === 'bchollos'
                                        ? 'text-white'
                                        : 'text-gray-800 hover:text-site-primary'
                                "
                                >{{ link.title }}</NuxtLink
                            >
                        </li>
                    </ul>
                </div>
                <div class="column dropdown">
                    <h3 class="column__head">
                        <span class="column__title">{{ footer.thirds_title }}</span>
                        <picture class="column__icon">
                            <img
                                class="column__icon-image"
                                :src="$assets.gray.arrowDown"
                                width="16px"
                                height="16px"
                                alt="flecha abajo"
                                title="flecha abajo"
                            />
                        </picture>
                    </h3>
                    <ul class="column-list">
                        <li class="column-list__item" v-for="(link, index) in footer.thirds" :key="index">
                            <a
                                v-if="link.external"
                                :href="link.url"
                                class="column-list__link"
                                :class="
                                    $config.public.variant === 'bchollos'
                                        ? 'text-white'
                                        : 'text-gray-800 hover:text-site-primary'
                                "
                                rel="nofollow noopener noreferrer"
                                target="_blank"
                            >
                                {{ link.title }}
                            </a>
                            <NuxtLink
                                no-prefetch
                                v-else
                                :to="link.url"
                                class="column-list__link"
                                :class="
                                    $config.public.variant === 'bchollos'
                                        ? 'text-white'
                                        : 'text-gray-800 hover:text-site-primary'
                                "
                                >{{ link.title }}</NuxtLink
                            >
                        </li>
                    </ul>
                </div>
                <div class="about-column">
                    <h3 class="about-column__head">
                        <span class="about-column__title">{{ footer.fourths_title }}</span>
                    </h3>
                    <p class="about-column__text">
                        <span>{{ $lang.components.layoutFooter.community }} &nbsp;</span>
                        <span class="font-semibold"> {{ $lang.components.layoutFooter.first_of }}&nbsp;</span>
                        <span>
                            {{ $lang.components.layoutFooter.enjoy }}
                        </span>
                    </p>
                    <ul class="about-column-list">
                        <li>
                            <button
                                type="button"
                                @click="openAccessibilityTools"
                                class="about-column-list__link"
                                :class="
                                    $config.public.variant === 'bchollos'
                                        ? 'text-white'
                                        : 'text-gray-800 hover:text-site-primary'
                                "
                            >
                                Abrir herramientas de accesibilidad
                            </button>
                        </li>
                    </ul>
                </div>
            </article>
        </section>

        <section
            v-if="$config.public.variant !== 'cupohosting'"
            class="legal"
            :class="
                $config.public.variant === 'bchollos' ? 'bg-site-secondary text-white' : 'bg-site-background'
            "
        >
            <p>
                Copyright © 2025
                <span>
                    {{ getNameByVariant($config.public.variant) }}
                </span>
                , una empresa de Hurtado Deals.
            </p>
        </section>
    </footer>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'
const RootStore = useRootStore()
const { $lang } = useNuxtApp()
const {
    public: { variant },
} = useRuntimeConfig()

const locationOptions = [
    {
        variant: 'megadescuentos' as 'megadescuentos',
        url: 'https://www.megadescuentos.com',
    },

    {
        variant: 'jdescuentos' as 'jdescuentos',
        url: 'https://www.jdescuentos.cl/',
    },
]

const legalOptions = [
    {
        variant: 'bchollos' as 'bchollos',
        name: 'Bchollos.es',
    },
    {
        variant: 'megadescuentos' as 'megadescuentos',
        name: 'MEGAdescuentos.com',
    },
    {
        variant: 'jdescuentos' as 'jdescuentos',
        name: 'Jdescuentos.cl',
    },
]

const dropdowns = ref<HTMLElement[]>([])
const buttonRef = ref() as Ref<HTMLAnchorElement>

onMounted(() => {
    dropdowns.value = Array.from(document.querySelectorAll('.dropdown')) as HTMLElement[]

    dropdowns.value.forEach((dropdown) => {
        dropdown.querySelector('.column__head')?.addEventListener('click', () => {
            dropdowns.value.forEach((otherDropdown) => {
                if (otherDropdown !== dropdown) {
                    otherDropdown.classList.remove('active')
                }
            })
            dropdown.classList.toggle('active')
        })
    })
    buttonRef.value = document.querySelector(
        'a[href="https://accessibility-widget.pages.dev"]',
    ) as HTMLAnchorElement
})

function getNameByVariant(variant: string) {
    const option = legalOptions.find((option) => option.variant === variant)
    return option ? option.name : ''
}

const footer = RootStore.layoutData?.data.footer
const dropdownCountries = locationOptions.filter((location) => location.variant !== variant)

const openAccessibilityTools = () => {
    buttonRef.value.click()
}
</script>

<style lang="postcss" scoped>
.app-footer {
    @apply flex flex-col items-center border-t;
    &-section {
        @apply container flex flex-col gap-y-[35px] px-[25px] py-[45px] lg:flex-row lg:justify-between lg:gap-x-7 lg:gap-y-0 2xl:gap-x-14;
        .logo {
            &__link {
                @apply mb-[15px] block w-32 sm:w-44;
            }
            &__image {
                @apply h-full w-full object-contain;
            }
        }
        & .columns {
            @apply flex flex-col md:flex-row md:justify-between md:gap-x-3;

            .column {
                &__head {
                    @apply mb-[15px] flex cursor-pointer justify-between text-lg font-bold;
                }
                &__icon {
                    @apply h-4 w-4 self-center md:hidden;
                    &-image {
                        @apply h-full w-full object-contain;
                    }
                }
                &__title {
                    @apply block font-semibold;
                }
                &__text {
                    @apply mb-2 text-xs;
                }
                &-list {
                    @apply hidden md:block;
                    &__item {
                        @apply mb-[15px] ml-[15px] md:ml-0;
                    }

                    &__link {
                        @apply text-sm transition-colors duration-100;
                    }

                    &.active {
                        @apply flex;
                    }
                }
            }
            .about-column {
                @apply flex basis-[24%] flex-col;
                &__head {
                    @apply mb-[15px] text-lg font-bold;
                }
                &__title {
                    @apply block font-semibold;
                }
                &__text {
                    @apply mb-2 text-sm;
                }
                &-list {
                    &__link {
                        @apply text-xs italic;
                    }
                }
            }
        }
    }

    & .legal {
        @apply w-full border-t px-[25px] py-[15px] md:px-0;

        p {
            @apply text-center text-xs;
        }
    }
}

.dropdown .column-list {
    @apply hidden md:block;
}

.dropdown.active .column-list {
    @apply block;
}

.dropdown.active .column__icon-image {
    @apply rotate-180;
}
</style>

<style>
a.asw-menu-btn {
    display: none !important;
}
</style>
